import { useCallback, useEffect, useRef, useState } from 'react';

export enum Asset {
  ETH = 'ETH',
  USDC = 'USDC',
  BUMP = 'BUMP',
  WBTC = 'WBTC',
  WETH = 'WETH',
  BETH = 'BETH',
  BUSDCETH = 'BUSDCETH',
}

export type AssetType = keyof typeof Asset;

const AssetUpdateEventKey = 'asset:update';

const MemoryCachedAssetsPrice = new Map<AssetType, number | null>();

export function useAssetPrice() {
  const isEventAttachedRef = useRef(false);
  const requestedAssetsRef = useRef<Set<AssetType>>(new Set());
  const [, reload] = useState({});

  const onAssetUpdateRef = useRef((ev: Event) => {
    if (ev instanceof CustomEvent) {
      if (requestedAssetsRef.current.has(ev.detail)) {
        reload({});
      }
    }
  });

  useEffect(() => {
    const onAssetUpdate = onAssetUpdateRef.current;

    return () => {
      document.removeEventListener(AssetUpdateEventKey, onAssetUpdate);
      isEventAttachedRef.current = false;
    };
  }, []);

  const getCoingeckoPrice = useCallback((asset: AssetType) => {
    const getAssetCoinGeckoId = (): string | undefined => {
      switch (asset) {
        case Asset.WBTC:
          return 'wrapped-bitcoin';
        case Asset.ETH:
          return 'ethereum';
        case Asset.WETH:
          return 'weth';
        case Asset.USDC:
          return 'usd-coin';
        case Asset.BUMP:
          return 'bumper';
        default:
          return undefined;
      }
    };

    const assetCoinGeckoId = getAssetCoinGeckoId();

    if (!assetCoinGeckoId) return Promise.resolve(undefined);

    return fetch(
      `https://api.coingecko.com/api/v3/simple/price?ids=${assetCoinGeckoId}&vs_currencies=usd`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
      .then((res) => res.json())
      .then((res) => res[assetCoinGeckoId].usd)
      .catch(() => undefined);
  }, []);

  const getAssetPrice = useCallback(
    (asset: AssetType): number | undefined => {
      if (!isEventAttachedRef.current) {
        isEventAttachedRef.current = true;
        document.addEventListener(
          AssetUpdateEventKey,
          onAssetUpdateRef.current,
        );
      }

      const assetSymbol = asset.toUpperCase() as AssetType;

      const cachedValue = MemoryCachedAssetsPrice.get(assetSymbol);

      if (cachedValue === null) return undefined;

      if (!requestedAssetsRef.current.has(assetSymbol)) {
        requestedAssetsRef.current.add(assetSymbol);

        if (cachedValue === undefined) {
          MemoryCachedAssetsPrice.set(assetSymbol, null);

          getCoingeckoPrice(assetSymbol)
            .then((price) => {
              MemoryCachedAssetsPrice.set(assetSymbol, price);

              document.dispatchEvent(
                new CustomEvent(AssetUpdateEventKey, { detail: assetSymbol }),
              );
            })
            .catch(() => {
              // eslint-disable-next-line no-console
              console.error(`Failed to fetch price for asset ${assetSymbol}`);
            });

          return undefined;
        }
      }

      return cachedValue;
    },
    [getCoingeckoPrice],
  );

  return {
    getAssetPrice,
  };
}

// Use this hook to get latest price in USD per asset
// TODO: Replace to fetch live prices
export function useAssetCurrentPrice(asset: AssetType): number {
  switch (asset) {
    case Asset.ETH:
      return 1800.0;
    case Asset.USDC:
      return 1.0;
    case Asset.BUMP:
      return 1.63;
    case Asset.WETH:
      return 1800.0;
    case Asset.WBTC:
      return 27323.212;
    default:
      return 1;
  }
}
