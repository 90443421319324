/* eslint-disable @typescript-eslint/no-explicit-any */
import { Route, Routes } from 'react-router-dom';

import { lazyIn } from '@bumper-dao/dapp/common/utils/lazy';
import { routes } from '@bumper-dao/dapp/config/routes';

const EarnDepositContainer = lazyIn(
  import('../earn-deposit/EarnDepositContainer'),
);
const EarnDepositDetails = lazyIn(import('../earn-deposit/EarnDepositDetails'));
const EarnDepositTransaction = lazyIn(
  import('../earn-deposit/EarnDepositTransaction'),
);

const EarnRenewContainer = lazyIn(import('../earn-renew/EarnRenewContainer'));
// const EarnRenewDetails = lazyIn(import('../earn-renew/EarnRenewDetails'));
const EarnRenewTransaction = lazyIn(
  import('../earn-renew/EarnRenewTransaction'),
);

const EarnWithdrawTransaction = lazyIn(
  import('../earn-withdraw/EarnWithdrawTransaction'),
);

export default function EarnRoutes() {
  return (
    <Routes>
      <Route path={routes.earnDeposit} element={<EarnDepositContainer />}>
        <Route index element={<EarnDepositDetails />} />
        <Route path="transaction/:step" element={<EarnDepositTransaction />} />
      </Route>
      <Route path={routes.earnRenew} element={<EarnRenewContainer />}>
        {/* <Route index element={<EarnRenewDetails />} /> */}
        <Route path="transaction/:step" element={<EarnRenewTransaction />} />
      </Route>
      <Route path={routes.earnWithdraw} element={<EarnWithdrawTransaction />} />
      <Route path="*" element={null} />
    </Routes>
  );
}
