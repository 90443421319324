import { ConnectWalletModal, IWallets } from '@bumper-dao/ui-kit';
import BrowserWalletLogo from '@bumper-dao/ui-kit/dist/images/48px/wallets/icon-wallet-browser.svg';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAccount, useConnect } from 'wagmi';

import {
  bumperGuideLink,
  disclaimerLink,
  privacyPolicyLink,
  termsAndConditionsLink,
} from '../../consts/link';
import { ConnectWalletModalContext } from '../../context';

export interface ConnectWalletPropsType {
  setIsOpened: (data: boolean) => void;
}

interface IWalletAssets {
  name: string;
  subTitle?: string;
  logo: string;
}

const getWalletLabel = (connectorId: string): IWalletAssets => {
  switch (connectorId) {
    case 'injected':
    default:
      return {
        name: 'Browser Wallet',
        subTitle: '(MetaMask, TrustWallet)',
        logo: BrowserWalletLogo,
      };
  }
};

export function ConnectWalletOverlay() {
  const navigate = useNavigate();
  const { isConnected } = useAccount();
  const { connectors, error, isLoading, connect } = useConnect();
  const { state: walletModalState, closeModal } = useContext(
    ConnectWalletModalContext,
  );

  const walletsArr: IWallets[] = connectors.map((connector) => {
    const walletAssets = getWalletLabel(connector.id);
    return {
      ...walletAssets,
      disabled: !connector.ready || isLoading,
      onClick: async () => {
        connect({ connector });
      },
    };
  });

  useEffect(() => {
    // TODO: We may want to offer an option to user to change wallet.
    if (isConnected) {
      closeModal();
    }
  }, [isConnected, closeModal]);

  // if (isConnected) {
  //   navigate('/');
  // }

  if ((!isLoading || !error) && walletModalState.open) {
    return (
      <ConnectWalletModal
        wallets={[walletsArr[0]]}
        withoutWalletClick={() => {
          closeModal();
          navigate(`/`, { replace: true });
        }}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        clickOutModal={() => {}}
        termsLink={termsAndConditionsLink}
        privacyLink={privacyPolicyLink}
        disclaimerLink={disclaimerLink}
        guideLink={bumperGuideLink}
      />
    );
  }

  return null;
}
