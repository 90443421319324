import * as Sentry from '@sentry/react';

import { ENV_TYPE } from '../common/consts/env';
import config from '../config';

const { sentry, env: environment } = config;

export const setupSentry = (): void => {
  if (environment !== ENV_TYPE.PROD) {
    Sentry.init({
      dsn: sentry.url,
      environment,
    });
  }
};
