import { CustomThemeProvider, GlobalStyle, light } from '@bumper-dao/ui-kit';
import { QueryClientProvider } from 'react-query';
import { ThemeProvider } from 'styled-components';
import { WagmiConfig } from 'wagmi';

import { ConsoleProvider } from './common/components/console/ConsoleContext';
import StepProvider from './common/components/step/StepContext';
import { ConnectWalletOverlay } from './common/components/wallets-modal/WalletsModal';
import TransactionErrorProvider from './common/context/transactionErrorContext';
import { ConnectWalletModalProvider } from './common/context/walletModalContext';
import ConsoleRoutes from './pages/app-routes/ConsoleRoutes';
import EarnRoutes from './pages/app-routes/EarnRoutes';
import ProtectRoutes from './pages/app-routes/ProtectRoutes';
import { reactQueryClient } from './tools/react-query';
import { wagmiClient } from './tools/wagmi';

function App() {
  return (
    <WagmiConfig client={wagmiClient}>
      <QueryClientProvider client={reactQueryClient}>
        <CustomThemeProvider>
          <ThemeProvider theme={light}>
            <GlobalStyle />
            <ConnectWalletModalProvider>
              <TransactionErrorProvider>
                <ConnectWalletOverlay />
                <ConsoleProvider>
                  <StepProvider>
                    <ConsoleRoutes />
                    <ProtectRoutes />
                    <EarnRoutes />
                  </StepProvider>
                </ConsoleProvider>
              </TransactionErrorProvider>
            </ConnectWalletModalProvider>
          </ThemeProvider>
        </CustomThemeProvider>
      </QueryClientProvider>
    </WagmiConfig>
  );
}

export default App;
