import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react';

enum ConnectWalletModalActionsTypes {
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
}

interface ConnectWalletModalActions {
  type: ConnectWalletModalActionsTypes;
}

interface ConnectWalletModalState {
  open: boolean;
}

const walletConnectModalReducer = (
  state: ConnectWalletModalState,
  action: ConnectWalletModalActions,
) => {
  switch (action.type) {
    case ConnectWalletModalActionsTypes.OPEN_MODAL:
      return {
        open: true,
      };
    case ConnectWalletModalActionsTypes.CLOSE_MODAL:
    default:
      return {
        open: false,
      };
  }
};

export const ConnectWalletModalContext = createContext<{
  state: ConnectWalletModalState;
  openModal: () => void;
  closeModal: () => void;
}>({
  state: {
    open: false,
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openModal: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeModal: () => {},
});

export function ConnectWalletModalProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [state, dispatch] = useReducer(walletConnectModalReducer, {
    open: false,
  });

  const openModal = useCallback(() => {
    dispatch({
      type: ConnectWalletModalActionsTypes.OPEN_MODAL,
    });
  }, []);

  const closeModal = useCallback(() => {
    dispatch({
      type: ConnectWalletModalActionsTypes.CLOSE_MODAL,
    });
  }, []);

  const value = useMemo(
    () => ({
      state,
      openModal,
      closeModal,
    }),
    [state, openModal, closeModal],
  );

  return (
    <ConnectWalletModalContext.Provider value={value}>
      {children}
    </ConnectWalletModalContext.Provider>
  );
}

export const useModalContext = () => {
  return useContext(ConnectWalletModalContext);
};
