import React, {
  ReactNode,
  ReactElement,
  useState,
  useMemo,
  useContext,
  useCallback,
} from 'react';

interface TransactionErrorMessage {
  name: string;
  message: string;
}

interface TransactionErrorContextType {
  errorStep?: string;
  warningStep?: string;
  transactionError: TransactionErrorMessage;
  handleTransactionWarning: (warning: boolean, warningStep: string) => void;
  handleTransactionError: (
    error: TransactionErrorMessage,
    errorStep: string,
  ) => void;
}

export const TransactionErrorContext =
  React.createContext<TransactionErrorContextType>(
    {} as TransactionErrorContextType,
  );

export default function TransactionErrorProvider({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const [errorStep, setErrorStep] = useState<string>('');
  const [warningStep, setWarningStep] = useState<string>('');
  const [transactionError, setTransactionError] =
    useState<TransactionErrorMessage>({
      name: '',
      message: '',
    });

  const handleTransactionError = useCallback(
    (error: Error, step: string) => {
      setTransactionError(error);
      setErrorStep(step ?? '');
    },
    [setTransactionError, setErrorStep],
  );

  const handleTransactionWarning = useCallback(
    (warning: boolean, step: string) => {
      if (warning) {
        setWarningStep(step);
      }
    },
    [setWarningStep],
  );

  const value = useMemo(() => {
    return {
      errorStep,
      warningStep,
      transactionError,
      handleTransactionWarning,
      handleTransactionError,
    };
  }, [
    errorStep,
    warningStep,
    transactionError,
    handleTransactionError,
    handleTransactionWarning,
  ]);

  return (
    <TransactionErrorContext.Provider value={value}>
      {children}
    </TransactionErrorContext.Provider>
  );
}

export const useTransactionErrorContext = () => {
  return useContext<TransactionErrorContextType>(TransactionErrorContext);
};
