import { StepStatus } from '@bumper-dao/ui-kit';
import React, {
  ReactNode,
  ReactElement,
  useState,
  useMemo,
  useContext,
} from 'react';

import { StepContextType } from './types';

export const StepContext = React.createContext<StepContextType>(
  {} as StepContextType,
);

export default function StepProvider({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const [step, setStep] = useState<string>('');
  const [steps, setSteps] = useState<string[]>([]);

  // This will tell you the status of step particularly
  const [stepStatus, setStepStatus] = useState<StepStatus[]>([]);
  const value = useMemo(() => {
    return { steps, stepStatus, setSteps, setStepStatus, step, setStep };
  }, [steps, stepStatus, setSteps, setStepStatus, step, setStep]);

  return <StepContext.Provider value={value}>{children}</StepContext.Provider>;
}

export const useStepContext = () => {
  return useContext(StepContext);
};
