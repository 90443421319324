import { createClient, configureChains, goerli, mainnet } from 'wagmi';
// import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { LedgerConnector } from 'wagmi/connectors/ledger';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { publicProvider } from 'wagmi/providers/public';
// import { ENV_VARS } from '../common/consts/envVars';
// import { getSupportedChains } from '../common/consts/chains';

const { chains, provider, webSocketProvider } = configureChains(
  [mainnet, goerli],
  [publicProvider()],
);

// console.log({ env: ENV_VARS.alchemyPrivateKey });

const connectors = [
  new InjectedConnector({
    chains,
    options: {
      name: 'Injected',
      shimDisconnect: true,
    },
  }),
  new WalletConnectConnector({
    chains,
    options: {
      qrcode: true,
    },
  }),
  new LedgerConnector(),
];

export const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
  webSocketProvider,
});
