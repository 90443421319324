import { Navigate, Route, Routes } from 'react-router-dom';

import { lazyIn } from '@bumper-dao/dapp/common/utils/lazy';
import { parentRoutes, routes } from '@bumper-dao/dapp/config/routes';

const Console = lazyIn(import('../console/Console'));
const EarnAsset = lazyIn(import('../earn-asset/EarnAsset'));
const EarnPosition = lazyIn(import('../earn-positions/EarnPosition'));
const ProtectAsset = lazyIn(import('../protect-asset/ProtectAssets'));
const ProtectPosition = lazyIn(import('../protect-position/ProtectPosition'));
const History = lazyIn(import('../history'));
const Market = lazyIn(import('../market'));

export default function ConsoleRoutes() {
  return (
    <Routes>
      <Route
        path={routes.home}
        element={<Navigate to={parentRoutes.console} replace />}
      />
      <Route path={parentRoutes.console}>
        <Route index element={<Console />} />
        <Route path={routes.protectAssets} element={<ProtectAsset />} />
        <Route path={routes.protectPositions} element={<ProtectPosition />} />
        <Route path={routes.earnAssets} element={<EarnAsset />} />
        <Route path={routes.earnPositions} element={<EarnPosition />} />
        <Route path={routes.history} element={<History />} />
        <Route path={routes.market} element={<Market />} />
        <Route path="*" element={null} />
      </Route>
      <Route path="*" element={null} />
    </Routes>
  );
}
