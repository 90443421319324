import { lazy } from 'react';

export function lazyIn(
  importPromise: Promise<{ default: unknown }>,
  timeout = 200,
) {
  return lazy(
    () =>
      new Promise((resolve) => {
        setTimeout(
          () => resolve(importPromise as Promise<{ default: never }>),
          timeout,
        );
      }),
  );
}
