export const parentRoutes = {
  console: '/console',
  protect: '/protect',
  earn: '/earn',
};

export const routes = {
  home: '/',
  notFound: '*',

  // Console Routes
  protectAssets: `${parentRoutes.console}/protect/assets`,
  protectPositions: `${parentRoutes.console}/protect/positions`,

  // Entry Routes
  protectDeposit: `${parentRoutes.protect}/deposit/:asset`,
  protectDepositTransaction: `${parentRoutes.protect}/deposit/:asset/transaction/:step`,

  // Renew Route
  protectRenew: `${parentRoutes.protect}/renew/:asset/:position`,
  protectRenewTransaction: `${parentRoutes.protect}/renew/:asset/:position/transaction/:step`,

  // Exit Routes
  protectCloseTransaction: `${parentRoutes.protect}/close/:asset/transaction/:step`,
  protectClaimTransaction: `${parentRoutes.protect}/claim/:asset/transaction/:step`,

  // Earn Routes
  earnAssets: `${parentRoutes.console}/earn/assets`,
  earnPositions: `${parentRoutes.console}/earn/positions`,
  earnDeposit: `${parentRoutes.earn}/deposit/:asset`,
  earnDepositTransaction: `${parentRoutes.earn}/deposit/:asset/transaction/:step`,

  // Renew Routes
  earnRenew: `${parentRoutes.earn}/renew/:asset/:position`,
  earnRenewTransaction: `${parentRoutes.earn}/renew/:asset/:position/transaction/:step`,

  // Withdrawal routes
  earnWithdraw: `${parentRoutes.earn}/withdraw/:asset/transaction/:step`,

  // History routes
  history: `${parentRoutes.console}/history`,

  // Market routes
  market: `${parentRoutes.console}/market`,
};

export const transactionRoutes = {
  confirmDetails: 'confirm-details',
  approveTransaction: 'wallet-approve',
  summary: 'summary',
  waitingConfirm: 'waiting-confirm',
  waiting: 'waiting',
  purchaseBump: 'purchase-bump',
};
