import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

export const setupI18n = (): void => {
  i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      lng: 'en',
      debug: true,
      fallbackLng: 'en',
      ns: ['common'],
      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
      },
      react: {
        useSuspense: true,
      },
      interpolation: {
        escapeValue: false,
      },
    });
};
