import { DappFeaturesName } from '@bumper-dao/ui-kit';
import { ReactNode, createContext, useContext, useMemo, useState } from 'react';

export interface ConsoleContextType {
  activeTab: DappFeaturesName | undefined;
  setActiveTab: (tab: DappFeaturesName | undefined) => void;
  resetTab: () => void;
}

const ConsoleContext = createContext<ConsoleContextType | undefined>(undefined);

export function useConsoleContext(): ConsoleContextType {
  const context = useContext(ConsoleContext);

  if (!context) {
    throw new Error('useConsoleContext must be within ConsoleProvider');
  }

  return context;
}

export function ConsoleProvider({ children }: { children: ReactNode }) {
  const [activeTab, setActiveTab] = useState<DappFeaturesName>();

  const value = useMemo(() => {
    return {
      activeTab,
      setActiveTab,
      resetTab() {
        setActiveTab(undefined);
      },
    };
  }, [activeTab]);

  return (
    <ConsoleContext.Provider value={value}>{children}</ConsoleContext.Provider>
  );
}
