import * as coins from './coins';

import { ENV_TYPE } from '../common/consts/env';

export type ConfigType = {
  env: string;
  sentry: {
    url: string;
  };
  externalLinks: Record<string, string>;
  protectionDays: string[];
  protectionFloors: number[];
  earningPeriods: string[];
  riskTiers: number[];
};

export default {
  env: process.env.REACT_APP_ENV || ENV_TYPE.DEV,
  coins,
  sentry: {
    url: process.env.REACT_APP_SENTRY_URL || '',
  },
  externalLinks: {
    discord: 'https://discord.gg/YyzRws4Ujd',
    docs: 'https://docs.bumper.fi/',
    support: 'mailto:support@bumper.fi?subject=Beta Support',
    reportBug:
      "https://docs.google.com/forms/d/e/1FAIpQLSd1kwjtkV9gWLKTzikUly2uXjq0h__p7pAJtkPPUAd-Da91Dg/viewform'",
  },
  protectionDays: process.env.REACT_APP_PROTECTION_DAYS
    ? JSON.parse(process.env.REACT_APP_PROTECTION_DAYS)
    : ['30', '60', '90', '120', '150'],
  protectionFloors: process.env.REACT_APP_PROTECTION_FLOORS
    ? JSON.parse(process.env.REACT_APP_PROTECTION_FLOORS)
    : [95, 90, 85, 80, 75],
  earningPeriods: process.env.REACT_APP_EARNING_PERIODS
    ? JSON.parse(process.env.REACT_APP_EARNING_PERIODS)
    : ['30', '60', '90', '120', '150'],
  riskTiers: process.env.REACT_APP_RISK_TIERS
    ? JSON.parse(process.env.REACT_APP_RISK_TIERS)
    : ['1', '2', '3', '4', '5'],
} as ConfigType;
