/* eslint-disable @typescript-eslint/no-explicit-any */
import { Route, Routes } from 'react-router-dom';

import { lazyIn } from '@bumper-dao/dapp/common/utils/lazy';
import { routes } from '@bumper-dao/dapp/config/routes';

const ProtectDepositFlowContainer = lazyIn(
  import('../protect-deposit/ProtectDepositFlowContainer'),
);
const ProtectDepositDetails = lazyIn(
  import('../protect-deposit/ProtectDepositDetails'),
);
const ProtectDepositTransaction = lazyIn(
  import('../protect-deposit/ProtectDepositTransaction'),
);

const ProtectRenewContainer = lazyIn(
  import('../protect-renew/ProtectRenewContainer'),
);
const ProtectRenewDetails = lazyIn(
  import('../protect-renew/ProtectRenewDetails'),
);
const ProtectRenewTransaction = lazyIn(
  import('../protect-renew/ProtectRenewTransaction'),
);

const ProtectCloseTransaction = lazyIn(
  import('../protect-close/ProtectCloseTransaction'),
);
const ProtectClaimTransaction = lazyIn(
  import('../protect-claim/ProtectClaimTransaction'),
);

export default function ProtectRoutes() {
  return (
    <Routes>
      <Route
        path={routes.protectDeposit}
        element={<ProtectDepositFlowContainer />}
      >
        <Route index element={<ProtectDepositDetails />} />
        <Route
          path="transaction/:step"
          element={<ProtectDepositTransaction />}
        />
      </Route>
      <Route path={routes.protectRenew} element={<ProtectRenewContainer />}>
        <Route index element={<ProtectRenewDetails />} />
        <Route path="transaction/:step" element={<ProtectRenewTransaction />} />
      </Route>
      <Route
        path={routes.protectCloseTransaction}
        element={<ProtectCloseTransaction />}
      />
      <Route
        path={routes.protectClaimTransaction}
        element={<ProtectClaimTransaction />}
      />
      <Route path="*" element={null} />
    </Routes>
  );
}
