import { lightColors } from '@bumper-dao/ui-kit';
import BUETHIcon from '@bumper-dao/ui-kit/dist/images/48px/tokens/beth.png';
import BUMPCoinIcon from '@bumper-dao/ui-kit/dist/images/48px/tokens/bump.png';
import BUUSDCIcon from '@bumper-dao/ui-kit/dist/images/48px/tokens/bUSDC.png';
import ETHCoinIcon from '@bumper-dao/ui-kit/dist/images/48px/tokens/eth.png';
import USDCCoinIcon from '@bumper-dao/ui-kit/dist/images/48px/tokens/usdc.svg';
import { BigNumber } from 'ethers';

import { Asset } from '../common/hooks/useAssetCurrentPrice';

export type Coin = {
  name: string;
  symbol: Asset;
  displaySymbol?: string;
  decimals: number;
};

export interface CoinDetails extends Coin {
  balance: string;
  balanceDecimal: BigNumber;
  value: string;
  price: string;
}

export const USDCoin: Coin = {
  name: 'USDC',
  symbol: Asset.USDC,
  decimals: 6,
};

export const BUMP: Coin = {
  name: 'BUMP',
  symbol: Asset.BUMP,
  decimals: 18,
};

export const ETH: Coin = {
  name: 'Ether',
  symbol: Asset.ETH,
  decimals: 18,
};

export const WETH: Coin = {
  name: 'Wrapped ETH',
  symbol: Asset.WETH,
  decimals: 18,
};

export const wBTC: Coin = {
  name: 'Wrapped BTC',
  symbol: Asset.WBTC,
  decimals: 8,
};

// POSITION TOKENS : START
export const BETH: Coin = {
  name: 'Bumpered ETH',
  displaySymbol: 'bETH',
  symbol: Asset.BETH,
  decimals: 18,
};

export const BUSDCETH: Coin = {
  name: 'Bumpered USDC',
  symbol: Asset.BUSDCETH,
  displaySymbol: 'bUSDCETH',
  decimals: 18,
};
// POSITION TOKENS : END

export const CoinAliases = new Map<string, string>([
  ['WETH', 'ETH'],
  ['wBTC', 'BTC'],
]);

export const CoinStables = new Map<string, string>([
  ['ETH', 'WETH'],
  ['BTC', 'wBTC'],
]);

export const CoinProtections = new Map<string, string>([
  ['ETH', 'bETH'],
  ['BTC', 'bBTC'],
]);

export const tokensIcons: { [key: string]: string } = {
  [USDCoin.symbol]: USDCCoinIcon,
  [BUMP.symbol]: BUMPCoinIcon,
  [ETH.symbol]: ETHCoinIcon,
  [WETH.symbol]: ETHCoinIcon,
  [wBTC.symbol]: ETHCoinIcon,
  [BETH.symbol]: BUETHIcon,
  [BUSDCETH.symbol]: BUUSDCIcon,
};

export const tokensBackground: { [key: string]: string } = {
  [USDCoin.symbol]: lightColors.secondary.darkBlue,
  [BUMP.symbol]: lightColors.secondary[500],
  [ETH.symbol]: lightColors.secondary.white,
  [WETH.symbol]: lightColors.secondary.white,
  [wBTC.symbol]: lightColors.secondary.white,
};
